import React, {useState, useEffect} from "react";
import {Button, Col, Descriptions, Form, Input, Row, Select, Space, Switch, Typography} from "antd";
import {MinusCircleOutlined, UpCircleOutlined, DownCircleOutlined} from '@ant-design/icons';
import {collection, doc, getDocs, getFirestore, query, setDoc, updateDoc} from "firebase/firestore";
import {initializeApp} from "firebase/app";
import {useQuery} from "@tanstack/react-query";
import {objFormatterForFirestore} from '../../function/tools';

const firebaseConfig = {
    apiKey: process.env.FIREBASE_YUZURUN_API_KEY,
    authDomain: "rx-0002.firebaseapp.com",
    projectId: "rx-0002",
    appId: process.env.FIREBASE_YUZURUN_APP_ID
};
const planList = initializeApp(firebaseConfig);
const fs = getFirestore(planList);

export default (props) => {
    const [edited, setEdited] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formValue, setFormValue] = useState({});
    const updateHandler = async (value) => {
        setLoading(true);
        const formattedValue = objFormatterForFirestore(value);
        const ref = doc(fs, "method", value?.method_key);
        props.isNew
            ? await setDoc(ref, formattedValue)
            : await updateDoc(ref, formattedValue)
        setLoading(false);
        setEdited(false);
        props.onFinish();
    }

    return (
        <>
            <Form
                labelCol={{
                    span: 4,
                }}
                layout="horizontal"
                initialValues={props.defaultValue}
                onValuesChange={(changedValues, allValues) => {
                    setEdited(true);
                    setFormValue(allValues);
                }}
                onFinish={(v) => {
                    updateHandler(v);
                }}
                onFinishFailed={(v) => {
                    console.log(v[0].name);
                }}
            >
                <Form.Item label="公開" name='publish'>
                    <Switch defaultChecked={props.defaultValue?.publish}/>
                </Form.Item>
                <Form.Item label="タイトル" name='title'
                           rules={[
                               {
                                   required: true,
                               },
                           ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item label="内部タイトル" name='title_internal'
                           rules={[
                               {
                                   required: true,
                               },
                           ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item label="Method Key" name='method_key'
                           rules={[
                               {
                                   required: true,
                               },
                           ]}
                >
                    <Input defaultValue={props.isNew ? '' : props.defaultValue?.method_key}/>
                </Form.Item>
                <Form.Item label="概要" name='description'>
                    <Input.TextArea
                        placeholder="テキスト"
                        rows={4}
                    />
                </Form.Item>
                <Form.Item label="タグ" name='tags'>
                    <Select
                        mode="tags"
                        placeholder="タグを入力（Enterで決定）"
                    />
                </Form.Item>
                <Descriptions
                    bordered
                    layout='horizontal'
                    column={1}
                    labelStyle={{width: '200px'}}
                >
                    <Descriptions.Item label="事前準備">
                        <FormListDescription name='account_setting' formValue={formValue}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="梱包・発送準備">
                        <FormListDescription name='package_prep' formValue={formValue}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="発送">
                        <FormListDescription name='send' formValue={formValue}/>
                    </Descriptions.Item>
                </Descriptions>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={!edited}
                    >
                        {props.isNew ? '新規登録' : '更新'}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

const FormListDescription = (props) => {
    const queryOptions = async () => {
        const methodsRef = collection(fs, 'method_content');
        const q = query(methodsRef);
        const querySnapshot = await getDocs(q);
        let output = [];
        querySnapshot.forEach((doc, index) => {
            let data = doc.data();
            data.key = index;
            output.push({value: data.method_content_key, label: data.title_internal})
        });
        return output
    }

    const {
        data: optionsData,
        status: optionsStatus,
        refetch: optionsRefetch
    } = useQuery({
        queryKey: ['method-content-list'],
        queryFn: queryOptions,
        retry: 1
    })

    return (
        <Form.List name={props.name}>
            {(fields, {add, move, remove}, {errors}) => (
                <>
                    <Space direction='vertical'>
                        {fields.map(({key, name, ...restField}, index) => (
                            <Space align="baseline"
                                   style={{
                                       display: 'flex',
                                       marginBottom: 8
                                   }}>
                                <Row className='description-area'>
                                    <Col span={1}>
                                        <span>{index + 1}.</span>
                                    </Col>
                                    <Col span={23}>
                                        <Row>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'description_item']}
                                                wrapperCol={{span: 24}}
                                                style={{width: '600px'}}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="アイテムを選択"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={optionsData}
                                                    allowClear
                                                />
                                            </Form.Item>
                                        </Row>
                                        <Row>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'description']}
                                                wrapperCol={{span: 24}}
                                                style={{width: '600px'}}
                                            >
                                                <Input.TextArea
                                                    placeholder="説明テキスト"
                                                    rows={4}
                                                    disabled={props.formValue && !!props.formValue[props.name]?.[name]?.description_item}
                                                />
                                            </Form.Item>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <FormListButtons
                                                    name={[name, 'btnList']}
                                                    formValue={props.formValue}
                                                    disabled={props.formValue && !!props.formValue[props.name]?.[name]?.description_item}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <MinusCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => remove(name)}
                                />
                                <UpCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => move(index, index - 1)}
                                />
                                <DownCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => move(index, index + 1)}
                                />
                            </Space>
                        ))}
                    </Space>
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                        >
                            項目追加
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    )
}

const FormListButtons = (props) => {
    return (
        <Form.List name={props.name}>
            {(fields, {add, move, remove}, {errors}) => (
                <>
                    <Space direction='vertical'>
                        {fields.map(({key, name, ...restField}, index) => (
                            <Space
                                key={key}
                                style={{
                                    display: 'flex',
                                    marginBottom: 8
                                }}
                                align="baseline"
                            >
                                <Form.Item
                                    {...restField}
                                    name={[name, 'btnText']}
                                    wrapperCol={{span: 24}}
                                    style={{width: '200px'}}
                                    defaultValue={null}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="ボタンテキスト"
                                        disabled={props.disabled}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'btnHref']}
                                    wrapperCol={{span: 24}}
                                    style={{width: '200px'}}
                                    defaultValue={null}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="https://..."
                                        disabled={props.disabled}
                                    />
                                </Form.Item>
                                <MinusCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => remove(name)}
                                />
                                <UpCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => move(index, index - 1)}
                                />
                                <DownCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => move(index, index + 1)}
                                />
                            </Space>
                        ))}
                    </Space>
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => {
                                add();
                            }}
                            disabled={props.disabled}
                        >
                            ボタン追加
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    )
}
import React, {useState, useEffect, useRef} from 'react';
import {Typography, Table, Button, Input, Space, Tag, Row, Col, Modal, Spin, Popconfirm} from 'antd';
import {initializeApp} from "firebase/app";
import {getFirestore, collection, query, doc, getDocs, updateDoc, deleteDoc} from "firebase/firestore";
import {useQuery, useQueryClient, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SelectMethod} from './Components';
import MethodDetail from './MethodDetail';
import ColumnWithSearch from '../../component/block/TableColumnSearch'
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const firebaseConfig = {
    apiKey: process.env.FIREBASE_YUZURUN_API_KEY,
    authDomain: "rx-0002.firebaseapp.com",
    projectId: "rx-0002",
    appId: process.env.FIREBASE_YUZURUN_APP_ID
};
const planList = initializeApp(firebaseConfig);
const fs = getFirestore(planList);

const queryClient = new QueryClient();

export default (props) => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <FirebaseContent/>
            </QueryClientProvider>
        </>
    )
}

const FirebaseContent = () => {
    const [open, setOpen] = useState(false);
    const [isNew, setIsNew] = useState();
    const [idForEdit, setIdForEdit] = useState('');
    const [dataForEdit, setDataForEdit] = useState({});
    const [loading, setLoading] = useState(false);

    const queryFn = async () => {
        const methodsRef = collection(fs, 'method');
        const q = query(methodsRef);
        const querySnapshot = await getDocs(q);
        let output = [];
        querySnapshot.forEach((doc, index) => {
            let data = doc.data();
            data.key = index;
            output.push(data)
        });
        return output
    }

    const {
        data,
        status,
        refetch
    } = useQuery({
        queryKey: ['method-list'],
        queryFn: queryFn,
        retry: 1
    })

    const deleteHandler = async (method_key) => {
        setLoading(true);
        await deleteDoc(doc(fs, 'method', method_key));
        refetch();
        setLoading(false);
    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        検索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        リセット
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        中止
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    return (
        <>
            <Button
                type='primary'
                onClick={() => {
                    setIdForEdit(null);
                    setIsNew(true);
                    setOpen(true);
                }}
            >
                新規追加
            </Button>
            <Spin spinning={loading} tip="更新中...">
                <Table
                    dataSource={data}
                    rowKey={(record) => record.method_key}
                    sticky
                    onRow={(record, rowIndex) => {
                        return {
                            onDoubleClick: () => {
                                setIdForEdit(record.method_key);
                                setOpen(true);
                            },
                        };
                    }}
                    pagination={{
                        pageSize: 50,
                    }}
                >
                    <Table.Column
                        title='方法名（内部）'
                        dataIndex="title_internal"
                        key="method_name"
                        width='300px'
                        {...getColumnSearchProps("title_internal")}
                    />
                    <Table.Column
                        title='Method Key'
                        dataIndex="method_key"
                        key="method_key"
                        width='200px'
                        {...getColumnSearchProps("method_key")}
                    />
                    <ColumnWithSearch
                        title='ステータス'
                        dataIndex="publish"
                        key="publish"
                        render={(status) => status ? <Tag color="green">公開中</Tag> : <Tag color="red">未公開</Tag>}
                    />
                    <ColumnWithSearch
                        title='設定'
                        key="action"
                        render={(record) => (
                            <Space size="middle">
                                <Button type='link' onClick={() => {
                                    setIdForEdit(record.method_key);
                                    setOpen(true);
                                }}>
                                    編集
                                </Button>
                                <Button type='link' onClick={() => {
                                    setIsNew(true);
                                    setIdForEdit(record.method_key);
                                    setOpen(true);
                                }}>
                                    複製
                                </Button>
                                <Popconfirm
                                    title="削除しますか？"
                                    onConfirm={() => deleteHandler(record.method_key)}
                                    okText="削除"
                                    cancelText="キャンセル"
                                >
                                    <Button danger type='link'>
                                        削除
                                    </Button>
                                </Popconfirm>
                            </Space>
                        )}
                    />
                </Table>
            </Spin>
            <Modal
                title={isNew ? '新規追加' : '更新'}
                centered
                open={open}
                closable
                destroyOnClose
                footer={null}
                width={1040}
                onCancel={() => {
                    setOpen(false);
                    setIsNew(null);
                }}
            >
                <MethodDetail
                    method_id={idForEdit}
                    isNew={isNew}
                    onFinish={() => {
                        setLoading(true);
                        refetch();
                        setIsNew();
                        setOpen(false);
                        setLoading(false);
                    }}
                />
            </Modal>
        </>
    )
}
import React, {useState} from "react";
import {Button, Col, Descriptions, Form, Input, Row, Select, Space, Switch, Typography} from "antd";
import {MinusCircleOutlined, UpCircleOutlined, DownCircleOutlined} from '@ant-design/icons';
import {collection, doc, getFirestore, setDoc, updateDoc} from "firebase/firestore";
import {initializeApp} from "firebase/app";
import {objFormatterForFirestore} from '../../function/tools';

const firebaseConfig = {
    apiKey: process.env.FIREBASE_YUZURUN_API_KEY,
    authDomain: "rx-0002.firebaseapp.com",
    projectId: "rx-0002",
    appId: process.env.FIREBASE_YUZURUN_APP_ID
};
const planList = initializeApp(firebaseConfig);
const fs = getFirestore(planList);

export default (props) => {
    const [edited, setEdited] = useState(false);
    const [loading, setLoading] = useState(false);
    const updateHandler = async (value) => {
        setLoading(true);
        const formattedValue = objFormatterForFirestore(value);
        const ref = doc(fs, "method_content", value?.method_content_key);
        props.isNew
            ? await setDoc(ref, formattedValue)
            : await updateDoc(ref, formattedValue)
        setLoading(false);
        setEdited(false);
        props.onFinish();
    }

    return (
        <>
            <Form
                labelCol={{
                    span: 4,
                }}
                layout="vertical"
                initialValues={props.defaultValue}
                onFieldsChange={(v) => {
                    setEdited(true);
                }}
                onFinish={(v) => {
                    updateHandler(v);
                }}
                onFinishFailed={(v) => {
                    console.log(v[0].name);
                }}
            >
                <Form.Item label="公開" name='publish'>
                    <Switch defaultChecked={props.defaultValue?.publish}/>
                </Form.Item>
                <Form.Item label="内部タイトル" name='title_internal'
                           rules={[
                               {
                                   required: true,
                               },
                           ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item label="Method Content Key" name='method_content_key'
                           rules={[
                               {
                                   required: true,
                               },
                           ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item label="説明テキスト" name='description'>
                    <Input.TextArea
                        placeholder="テキスト"
                        rows={4}
                    />
                </Form.Item>
                <Row>
                    <Col span={24}>
                        <FormListButtons name={['btnList']}/>
                    </Col>
                </Row>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={!edited}
                    >
                        {props.isNew ? '登録' : '更新'}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

const FormListButtons = (props) => {
    return (
        <Form.List name={props.name}>
            {(fields, {add, move, remove}, {errors}) => (
                <>
                    <Space direction='vertical'>
                        {fields.map(({key, name, ...restField}, index) => (
                            <Space
                                key={key}
                                style={{
                                    display: 'flex',
                                    marginBottom: 8
                                }}
                                align="baseline"
                            >
                                <Form.Item
                                    {...restField}
                                    name={[name, 'btnText']}
                                    wrapperCol={{span: 24}}
                                    style={{width: '200px'}}
                                    defaultValue={null}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="ボタンテキスト"
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'btnHref']}
                                    wrapperCol={{span: 24}}
                                    style={{width: '200px'}}
                                    defaultValue={null}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="https://..."
                                    />
                                </Form.Item>
                                <MinusCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => remove(name)}
                                />
                                <UpCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => move(index, index - 1)}
                                />
                                <DownCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => move(index, index + 1)}
                                />
                            </Space>
                        ))}
                    </Space>
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => {
                                add();
                            }}
                        >
                            ボタン追加
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    )
}
import React, { useState } from 'react';
import { Typography, Image } from 'antd';

const { Title, Text, Link } = Typography;

export default () => {
    return (
        <>
            <Title level={1}>
                オクルン社内ツール「ユズルン」です！
            </Title>
            <Text style={{fontSize: '24px'}}>
                ←左のバーから機能を選んでください
            </Text>
        </>
    )
};
import React, {useState, useEffect, useRef} from 'react';
import {Typography, Tabs} from "antd";
import PlanList from './PlanList';
import MethodList from './MethodList';
import MethodContentList from './MethodContentList';

export default (props) => {
    return (
        <>
            <Typography.Title>配送表示設定</Typography.Title>
            <Tabs
                type="line"
                items={[
                    {
                        label: '配送方法',
                        key: 'methodList',
                        children: (
                            <>
                                <MethodList/>
                            </>
                        )
                    },
                    {
                        label: '配送方法コンテンツ',
                        key: 'methodContentList',
                        children: (
                            <>
                                <MethodContentList/>
                            </>
                        )
                    },
                    {
                        label: 'プラン一覧',
                        key: 'planList',
                        children: (
                            <>
                                <PlanList/>
                            </>
                        )
                    },
                ]}
            />
        </>
    )
}
import React from 'react';
import {initializeApp} from "firebase/app";
import {
    getFirestore,
    collection,
    doc,
    getDoc,
    updateDoc
} from "firebase/firestore";
import {useQuery, useQueryClient, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import MethodForm from './MethodContentForm';

const firebaseConfig = {
    apiKey: process.env.FIREBASE_YUZURUN_API_KEY,
    authDomain: "rx-0002.firebaseapp.com",
    projectId: "rx-0002",
    appId: process.env.FIREBASE_YUZURUN_APP_ID
};
const planList = initializeApp(firebaseConfig);
const fs = getFirestore(planList);
const queryClient = new QueryClient();

export default (props) => {
    return props.isNew ? (
        <>
            <MethodForm isNew={props.isNew} onFinish={() => props.onFinish()}/>
        </>
    ) : (
        <>
            <QueryClientProvider client={queryClient}>
                <FirebaseContent method_content_id={props.method_content_id} onFinish={() => props.onFinish()}/>
            </QueryClientProvider>
        </>
    )
}

const FirebaseContent = (props) => {
    const queryFn = async () => {
        const docRef = doc(fs, 'method_content', props.method_content_id);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            let data = docSnapshot.data();
            return {...data, result: 'OK'}
        } else {
            return {result: 'NG'}
        }
    }

    const {
        data,
        status,
        remove
    } = useQuery({
        queryKey: [`method-content-doc-${props.method_content_id}`],
        queryFn: queryFn,
        retry: 1
    })

    return status === 'loading' ? (
        <>
            Loading...
        </>
    ) : (
        <MethodForm defaultValue={data} isNew={props.isNew} onFinish={() => {props.onFinish(); remove();}}/>
    )
}
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, Input, Popconfirm, Select, Space, Table, Tag} from 'antd';
import {EditOutlined} from '@ant-design/icons';

const EditableContext = React.createContext(null);
const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          inputType,
                          options,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('保存に失敗しました:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
            >
                {
                    inputType === 'textArea' ? (
                        <Input.TextArea
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                            rows={4}
                        />
                    ) : inputType === 'text' ? (
                        <Input
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                        />
                    ) : inputType === 'select-single' ? (
                        <Select
                            ref={inputRef}
                            style={{
                                width: '100%',
                            }}
                            placeholder="選択してください"
                            options={options}
                            onChange={save}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            filterSort={(a, b) => {
                                return a.label > b.label ? 1 : -1
                            }}
                        />
                    ) : inputType === 'select-multiple' ? (
                        <Select
                            ref={inputRef}
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="選択してください"
                            options={options}
                            onBlur={save}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            filterSort={(a, b) => {
                                return a.label > b.label ? 1 : -1
                            }}
                        />
                    ) : (
                        <Input
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                        />
                    )
                }
            </Form.Item>
        ) : (
            <Space>
                <div
                    className="editable-cell-value-wrap"
                    onClick={toggleEdit}
                >
                    {Array.isArray(children[1])
                        ? children[1]?.map(child => <Tag>{child}</Tag>)
                        : children[1]}
                </div>
                <div
                    onClick={toggleEdit}
                >
                    <a><EditOutlined/></a>
                </div>
            </Space>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

export default (props) => {
    const [dataSource, setDataSource] = useState(props.dataSource);
    useEffect(() => {
        setDataSource(props.dataSource?.map((row, index) => {
            return {...row, key: index}
        }))
    }, [props.dataSource]);

    const defaultColumns = [
        ...props.defaultColumns,
        {
            title: '操作',
            dataIndex: 'operation',
            width: '100px',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title="削除しますか?" onConfirm={() => handleDelete(record.id)}>
                        <Button type='text' danger>削除</Button>
                    </Popconfirm>
                ) : null,
        },
    ];
    const handleDelete = (id) => {
        const newData = dataSource.filter((item) => item.id !== id);
        setDataSource(newData);
        props.onDelete(id);
    };
    const handleAdd = () => {
        const newData = {};
        Object.keys(dataSource[0]).forEach((key) => {
            newData[key] = '';
        })
        setDataSource([...dataSource, newData]);
        props.onCreate(newData);
    };
    const handleSave = (row) => {
        //upload to firebase
        let newObj = {};
        Object.keys(row).forEach(key => {
            if (key !== 'key') {
                newObj[key] = row[key]
            }
        });
        props.onUpdate(newObj);

        //update display
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                inputType: col.inputType,
                options: col.options,
                handleSave,
            }),
        };
    });
    return (
        <div>
            <Button
                onClick={handleAdd}
                type="primary"
                style={{
                    marginBottom: 16,
                }}
                disabled={!props.addEnabled}
            >
                行を追加
            </Button>
            <Table
                components={components}
                // rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
            />
        </div>
    );
};
import React, {useState, useEffect, useRef} from 'react';
import {Typography, Table, Button, Input, Space, Tag, Row, Col, message, Progress, Spin} from 'antd';
import {initializeApp} from "firebase/app";
import {getFirestore, collection, query, doc, getDocs, addDoc, updateDoc, deleteDoc} from "firebase/firestore";
import {useQuery, useQueryClient, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import EditableTable from '../../../component/block/EditableTable';
import {objFormatterForFirestore} from "../../../function/tools";

const firebaseConfig = {
    apiKey: process.env.FIREBASE_YUZURUN_API_KEY,
    authDomain: "rx-0002.firebaseapp.com",
    projectId: "rx-0002",
    appId: process.env.FIREBASE_YUZURUN_APP_ID
};
const fsList = initializeApp(firebaseConfig);
const fs = getFirestore(fsList);

const queryClient = new QueryClient();

export default (props) => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <FirebaseContent/>
            </QueryClientProvider>
        </>
    )
}

const FirebaseContent = () => {
    const [loading, setLoading] = useState(false);
    const queryFn = async () => {
        const plansRef = collection(fs, 'internal-link');
        const q = query(plansRef);
        const querySnapshot = await getDocs(q);
        let output = [];
        querySnapshot.forEach((doc, index) => {
            let data = doc.data();
            data.key = index;
            data.id = doc.id;
            output.push(data)
        });
        return output
    }

    const {
        data,
        status,
        refetch,
    } = useQuery({
        queryKey: ['internal-link'],
        queryFn: queryFn,
        retry: 1
    })

    const updateHandler = async (row) => {
        setLoading(true);
        console.log(row);
        const formattedValue = objFormatterForFirestore(row);
        const ref = doc(fs, 'internal-link', row.id);
        await updateDoc(ref, formattedValue);
        setLoading(false);
    }
    const createHandler = async (row) => {
        setLoading(true);
        const formattedValue = objFormatterForFirestore(row);
        const docRef = await addDoc(collection(fs, 'internal-link'), formattedValue);
        console.log('Row successfully added. ID:' + docRef.id);
        refetch();
        setLoading(false);
    }
    const deleteHandler = async (id) => {
        setLoading(true);
        await deleteDoc(doc(fs, 'internal-link', id.toString()))
        console.log('Row successfully deleted. ID:' + id);
        refetch();
        setLoading(false);
    }

    return (
        <>
            <Spin spinning={loading}>
                <EditableTable
                    dataSource={data}
                    defaultColumns={[
                        {
                            title: '表示名',
                            dataIndex: 'display_name',
                            width: '200px',
                            editable: true,
                        },
                        {
                            title: 'リンク先',
                            dataIndex: 'href',
                            editable: true,
                            render: (v, record) => {
                                return record.is_external
                                    ? <a href={`${v}`} target='_blank'>{v}</a>
                                    : <a href={`https://dev.ocurun.com${v}`} target='_blank'>{v}</a>
                            }
                        },
                        {
                            title: '内部/外部',
                            dataIndex: 'is_external',
                            width: '100px',
                            inputType: 'select-single',
                            options: [
                                {
                                    label: '外部',
                                    value: true,
                                },
                                {
                                    label: '内部',
                                    value: false,
                                },
                            ],
                            editable: true,
                            render: (v) => v ? <Tag>外部</Tag> : <Tag>内部</Tag>
                        },
                        {
                            title: '公開',
                            dataIndex: 'publish',
                            width: '150px',
                            inputType: 'select-single',
                            options: [
                                {
                                    label: '公開',
                                    value: true,
                                },
                                {
                                    label: '非公開',
                                    value: false,
                                },
                            ],
                            editable: true,
                            render: (v) => v ? <Tag color="green">公開中</Tag> : <Tag color="red">非公開</Tag>
                        },
                        {
                            title: 'target',
                            dataIndex: 'target',
                            inputType: 'select-multiple',
                            options: [
                                {
                                    label: '_blank',
                                    value: '_blank',
                                },
                            ],
                            editable: true,
                        },
                    ]}
                    onCreate={(row) => createHandler(row)}
                    onUpdate={(row) => updateHandler(row)}
                    onDelete={(id) => deleteHandler(id)}
                    addEnabled={true}
                />
            </Spin>
        </>
    )
}
import React, {useState, useEffect, useRef} from 'react';
import {Typography, Tabs} from "antd";
import ItemList from './ItemList';

export default (props) => {
    return (
        <>
            <Typography.Title>商品情報設定</Typography.Title>
            <Tabs
                type="line"
                items={[
                    {
                        label: '商品一覧',
                        key: 'itemList',
                        children: (
                            <>
                                <ItemList/>
                            </>
                        )
                    },
                ]}
            />
        </>
    )
}
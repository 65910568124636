import React, {useState, useEffect} from 'react';
import {Outlet, createBrowserRouter, RouterProvider, useParams, useOutlet, useNavigate} from 'react-router-dom';
import './App.css';
import LayoutDefault from './page/Layout/Default';
import Top from './page/Top/App';
import Item from './page/Item/App';
import Plan from './page/Plan/App';
import PageConfig from './page/Page/App';

const router = createBrowserRouter([
  {
    path: "*",
    element: <LayoutDefault/>,
    children: [
      {
        index: true,
        element: <Top/>
      },
      {
        path: 'item',
        element: <Item/>
      },
      {
        path: 'plan',
        element: <Plan/>
      },
      {
        path: 'page-config',
        element: <PageConfig/>
      },
    ]
  },
]);


export default () => {
  return (
      <RouterProvider
          router={router}
          fallbackElement={<LayoutDefault/>}
      />
  )
};
import React, {useState, useEffect, useRef} from 'react';
import {Typography, Table, Button, Input, Space, Tag, Row, Col, message, Progress, Spin} from 'antd';
import {initializeApp} from "firebase/app";
import {getFirestore, collection, query, doc, getDocs, updateDoc} from "firebase/firestore";
import {useQuery, useQueryClient, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import EditableTable from '../../../component/block/EditableTable';
import {objFormatterForFirestore} from "../../../function/tools";

const firebaseConfig = {
    apiKey: process.env.FIREBASE_YUZURUN_API_KEY,
    authDomain: "rx-0002.firebaseapp.com",
    projectId: "rx-0002",
    appId: process.env.FIREBASE_YUZURUN_APP_ID
};
const planList = initializeApp(firebaseConfig);
const fs = getFirestore(planList);

const queryClient = new QueryClient();

export default (props) => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <FirebaseContent/>
            </QueryClientProvider>
        </>
    )
}

const FirebaseContent = () => {
    const [loading, setLoading] = useState(false);
    const queryFn = async () => {
        const plansRef = collection(fs, 'page-seo');
        const q = query(plansRef);
        const querySnapshot = await getDocs(q);
        let output = [];
        querySnapshot.forEach((doc, index) => {
            let data = doc.data();
            data.key = index;
            output.push(data)
        });
        return output
    }

    const {
        data,
        status,
        refetch,
    } = useQuery({
        queryKey: ['directory-list'],
        queryFn: queryFn,
        retry: 1
    })

    const updateHandler = async (row) => {
        setLoading(true);
        const formattedValue = objFormatterForFirestore(row);
        const ref = doc(fs, "page-seo", row.id);
        await updateDoc(ref, formattedValue);
        setLoading(false);
    }
    const createHandler = async (row) => {
        setLoading(true);
        const formattedValue = objFormatterForFirestore(row);
        const ref = fs.collection("page-seo").doc();
        await ref.set(formattedValue);
        setLoading(false);
    }

    return (
        <>
            <Spin spinning={loading}>
                <EditableTable
                    dataSource={data}
                    defaultColumns={[
                        {
                            title: 'タイトル（表示用）',
                            dataIndex: 'title',
                            width: '150px',
                            inputType: 'textArea',
                            editable: true,
                        },
                        {
                            title: 'ディレクトリ',
                            dataIndex: 'directory',
                            width: '150px',
                            editable: true,
                        },
                        {
                            title: 'Page ID',
                            dataIndex: 'pageId',
                            width: '150px',
                            editable: true,
                        },
                        {
                            title: 'Description',
                            dataIndex: "description",
                            key: "description",
                            width: '200px',
                            inputType: 'textArea',
                            editable: true,
                        },
                        {
                            title: 'H1',
                            width: '200px',
                            dataIndex: "h1",
                            key: "h1",
                            inputType: 'textArea',
                            editable: true,
                        },
                        {
                            title: 'Canonical',
                            width: '200px',
                            dataIndex: "canonical",
                            key: "canonical",
                            inputType: 'text',
                            editable: true,
                        },
                        {
                            title: 'robots',
                            dataIndex: "robots",
                            key: "robots",
                            inputType: 'select-multiple',
                            options: [
                                {
                                    label: 'noindex',
                                    value: 'noindex',
                                },
                                {
                                    label: 'nofollow',
                                    value: 'nofollow',
                                },
                                {
                                    label: 'noarchive',
                                    value: 'noarchive',
                                },
                                {
                                    label: 'noimageindex',
                                    value: 'noimageindex',
                                },
                            ],
                            editable: true,
                        },
                    ]}
                    onCreate={(row) => createHandler(row)}
                    onUpdate={(row) => updateHandler(row)}
                />
            </Spin>
        </>
    )
}
export const objFormatterForFirestore = (obj) => {
    let keys = Object.keys(obj);
    let output = {}
    keys.forEach((key) => {
        if (key === 'id') {
            return;
        }
        if (obj[key]) {
            if (Array.isArray(obj[key]) && typeof obj[key][0] === 'object') {
                output[key] = []
                let subArr = obj[key];
                subArr.forEach((row) => {
                    let subArrKeys = Object.keys(row);
                    let subOutput = {};
                    subArrKeys.forEach((subKey) => {
                        row[subKey] && (subOutput[subKey] = row[subKey])
                    })
                    output[key].push(subOutput);
                })
            } else {
                output[key] = obj[key]
            }
        }
    })
    output.publish || (output.publish = false);
    return output;
}

const newLineToBr = (value) => {
    if (typeof value === "string" || value instanceof String) {
        console.log('isString', value)
        return value.replace(/\r?\n/g, '<br />')
    } else {
        console.log('notString', value)
        return value
    }
}
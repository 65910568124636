import React, {useState, useEffect, useRef} from 'react';
import {Typography, Tabs} from "antd";
import SEOBasic from './SEO/App';
import InternalLink from './InternalLink/App';
import PageLink from './PageLink/App';
import ShipService from './ShipService/App';

export default (props) => {
    return (
        <>
            <Typography.Title>ページ設定</Typography.Title>
            <Tabs
                type="line"
                items={[
                    {
                        label: 'SEO基本設定',
                        key: 'seoBasic',
                        children: (
                            <>
                                <SEOBasic />
                            </>
                        )
                    },
                    {
                        label: '内部リンク',
                        key: 'internalLink',
                        children: (
                            <>
                                <InternalLink />
                            </>
                        )
                    },
                    {
                        label: '内部リンク紐付け',
                        key: 'pageLink',
                        children: (
                            <>
                                <PageLink />
                            </>
                        )
                    },
                    {
                        label: '配送サービスページ',
                        key: 'shipService',
                        children: (
                            <>
                                <ShipService />
                            </>
                        )
                    },
                ]}
            />
        </>
    )
}
import React, {useState, useEffect, useRef} from 'react';
import {Typography, Table, Button, Input, Space, Tag, Row, Col, message, Progress} from 'antd';
import {initializeApp} from "firebase/app";
import {getFirestore, collection, query, doc, getDocs, updateDoc} from "firebase/firestore";
import {useQuery, useQueryClient, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SelectMethod} from './Components';
import ColumnWithSearch from '../../component/block/TableColumnSearch';
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import EditForm from './PlanForm';

const firebaseConfig = {
    apiKey: process.env.FIREBASE_YUZURUN_API_KEY,
    authDomain: "rx-0002.firebaseapp.com",
    projectId: "rx-0002",
    appId: process.env.FIREBASE_YUZURUN_APP_ID
};
const planList = initializeApp(firebaseConfig);
const fs = getFirestore(planList);

const queryClient = new QueryClient();

export default (props) => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <FirebaseContent/>
            </QueryClientProvider>
        </>
    )
}

const FirebaseContent = () => {
    const queryFn = async () => {
        const plansRef = collection(fs, 'plan');
        const q = query(plansRef);
        const querySnapshot = await getDocs(q);
        let output = [];
        querySnapshot.forEach((doc, index) => {
            let data = doc.data();
            data.key = index;
            output.push(data)
        });
        return output
    }

    const {
        data,
        status,
        refetch,
    } = useQuery({
        queryKey: ['plan-list'],
        queryFn: queryFn,
        retry: 1
    })

    const queryOptions = async () => {
        const methodsRef = collection(fs, 'method');
        const q = query(methodsRef);
        const querySnapshot = await getDocs(q);
        let output = [];
        querySnapshot.forEach((doc, index) => {
            let data = doc.data();
            data.key = index;
            output.push({value: data.method_key, label: data.title_internal})
        });
        return output
    }

    const {
        data: optionsData,
        status: optionsStatus,
        refetch: optionsRefetch
    } = useQuery({
        queryKey: ['method-list'],
        queryFn: queryOptions,
        retry: 1
    })

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        検索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        リセット
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        中止
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        // setSortedInfo(sorter);
    };
    const getColumnFilterProps = (dataIndex, filters) => ({
        filters: filters,
        filteredValue: filteredInfo[dataIndex] || null,
        onFilter: (value, record) => record[dataIndex].includes(value),
    })

    const configFinishedCount = data?.filter(v => v.station_agent_from.every(agent => agent.method_key_list?.length > 0)).length

    return (
        <>
            <Row>
                <Col span={18}>
                    <Button
                        type='primary'
                        onClick={() => {
                            optionsRefetch();
                            message.success('更新しました');
                        }}
                    >
                        配送方法更新
                    </Button>
                    <Progress
                        percent={
                            Math.round((configFinishedCount / data?.length) * 100)
                        }
                        format={(number) => `${number||'-'}% (${configFinishedCount||'-'}/${data?.length||'-'})`}
                    />
                </Col>
            </Row>
            <Table
                dataSource={data?.map(v => {
                    return {
                        ...v,
                        config_status: v.station_agent_from.every(agent => agent.method_key_list?.length > 0) ? '設定完了' : '未設定'
                    }
                })}
                rowKey={(record) => record.plan_id}
                sticky
                onChange={handleChange}
                expandable={{
                    expandedRowRender: (record) => (
                        <EditForm
                            record={record}
                            options={optionsData}
                            onUpdate={() => {
                                refetch();
                            }}
                        />
                    ),
                }}
                expandRowByClick
                pagination={{
                    pageSize: 50,
                }}
            >
                <Table.Column
                    title='Plan ID'
                    dataIndex="plan_id"
                    key="plan_id"
                    width='150px'
                    {...getColumnSearchProps("plan_id")}
                    render={(id) => <a href={`https://dev.ocurun.com/ship/plan/${id}`} target='_blank'>{id}</a>}
                />
                <Table.Column
                    title='配送会社'
                    dataIndex="carrier_name"
                    key="carrier_name"
                    width='150px'
                    {...getColumnSearchProps("carrier_name")}
                />
                <Table.Column
                    title='サービス名'
                    dataIndex="service_name"
                    key="service_name"
                    width='200px'
                    {...getColumnSearchProps("service_name")}
                />
                <Table.Column
                    title='割引名'
                    width='200px'
                    dataIndex="discount_plan_name"
                    key="discount_plan_name"
                    {...getColumnSearchProps("discount_plan_name")}
                />
                <Table.Column
                    title='発送箇所（設定状況）'
                    dataIndex="station_agent_from"
                    key="station_agent_from"
                    render={(list) => (
                        <>
                            {list.map((tag) => {
                                return (
                                    <Tag
                                        key={tag.station_agent_key}
                                        color={tag.method_key_list?.length > 0 ? 'green' : 'red'}
                                    >
                                        {tag.station_agent_name}
                                        {tag.method_key_list?.length > 0 ? '（設定済）' : '（未設定）'}
                                    </Tag>
                                );
                            })}
                        </>
                    )}
                />
                <Table.Column
                    title='設定状況'
                    width='100px'
                    dataIndex="config_status"
                    key="config_status"
                    {...getColumnSearchProps("config_status")}
                />
            </Table>
        </>
    )
}

const ExpandedArea = (props) => {
    let record = props.record;
    const [draft, setDraft] = useState(record.station_agent_from);
    const [edited, setEdited] = useState(false);
    const [loading, setLoading] = useState(false);
    const updateHandler = async () => {
        setLoading(true);
        const ref = doc(fs, "plan", record.plan_id);
        await updateDoc(ref, {
            station_agent_from: draft
        });
        props.onUpdate();
        setLoading(false);
        setEdited(false);
    }
    return (
        <Space direction='vertical' style={{width: '100%'}}>
            {record.station_agent_from?.map((agent) => {
                return (
                    <Row justify='middle' gutter={16}>
                        <Col span={4}>
                            <Typography.Text strong>{agent.station_agent_name}</Typography.Text>
                        </Col>
                        <Col span={20}>
                            <SelectMethod
                                defaultValue={agent.method_key_list}
                                onChange={(v) => {
                                    setDraft(draft.map((row) => {
                                        return row.station_agent_name === agent.station_agent_name
                                            ? {...row, method_key_list: v}
                                            : row
                                    }))
                                    setEdited(true);
                                }}
                                options={props.options}
                            />
                        </Col>
                    </Row>
                )
            })}
            <Row>
                <Button
                    type='primary'
                    disabled={!edited}
                    loading={loading}
                    onClick={() => {
                        updateHandler();
                    }}
                >
                    更新
                </Button>
            </Row>
        </Space>
    )
}


// {...getColumnFilterProps('station_agent_from_config_status', [
//     {
//         text: '設定完了',
//         value: '設定完了',
//     },
//     {
//         text: '未設定',
//         value: '未設定',
//     },
// ])}


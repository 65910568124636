import {useOutlet, ScrollRestoration, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {DragOutlined, SettingOutlined, DatabaseOutlined} from '@ant-design/icons';
import {ConfigProvider, Layout, Menu, theme} from "antd";

const {Content, Footer, Sider} = Layout;

const items = [
    {
        key: 'item',
        label: '商品情報',
        icon: <DragOutlined />
    },
    {
        key: 'plan',
        label: '配送プラン',
        icon: <SettingOutlined />
    },
    {
        key: 'page-config',
        label: 'ページ設定',
        icon: <DatabaseOutlined />
    },
    {
        key: 'media',
        label: 'メディア記事',
        disabled: true
    },
    {
        key: 'data-traffic',
        label: 'トラフィックデータ',
        disabled: true
    }
]

export default () => {
    const outlet = useOutlet();
    const navigate = useNavigate();
    const [directory, setDirectory] = useState('')
    const {
        token: {colorBgContainer},
    } = theme.useToken();
    return (
        <ConfigProvider
            theme={{
                token: {
                    // colorPrimary: '#ef701f',
                    fontSizeHeading2: '24px',
                    lineHeightHeading2: "30px"
                },
            }}
        >
            <Layout hasSider>
                <Sider
                    style={{
                        background: colorBgContainer,
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        bottom: 0,
                    }}
                >
                    <div
                        style={{
                            height: 32,
                            margin: 16
                        }}
                        onClick={() => {
                            setDirectory('');
                            navigate('');
                        }}
                    >
                        <img
                            src="https://ocurun.imgix.net/img/logo/v1.svg"
                            alt="ocurun"
                            style={{
                                width: "148.3px",
                                maxHeight: "100%",
                                maxWidth: "100%"
                            }}
                        />
                    </div>
                    <Menu
                        theme="light"
                        mode="inline"
                        items={items}
                        selectedKeys={[directory]}
                        onSelect={(v) => {
                            setDirectory(v.key);
                            navigate(v.key);
                        }}
                    />
                </Sider>
                <Layout
                    className="site-layout"
                    style={{
                        marginLeft: 200,
                        background: colorBgContainer,
                    }}
                >
                    <Content
                        className='Page'
                        style={{
                            overflow: 'initial',
                        }}
                    >
                        {outlet ||
                            <div style={{
                                height: "80vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "30px"
                            }}>
                                Loading...
                            </div>
                        }
                    </Content>
                    <Footer
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        オクルン ｜ Retail Experience
                    </Footer>
                </Layout>
            </Layout>
            <ScrollRestoration/>
        </ConfigProvider>
    )
        ;
}
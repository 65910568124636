import React, {useState, useEffect, useRef} from 'react';
import {Typography, Table, Button, Input, Space, Tag, Row, Col, message, Progress} from 'antd';
import {initializeApp} from "firebase/app";
import {getFirestore, collection, query, doc, getDocs, updateDoc} from "firebase/firestore";
import {useQuery, useQueryClient, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import ColumnWithSearch from '../../component/block/TableColumnSearch';
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const firebaseConfig = {
    apiKey: process.env.FIREBASE_YUZURUN_API_KEY,
    authDomain: "rx-0002.firebaseapp.com",
    projectId: "rx-0002",
    appId: process.env.FIREBASE_YUZURUN_APP_ID
};
const planList = initializeApp(firebaseConfig);
const fs = getFirestore(planList);

const queryClient = new QueryClient();

export default (props) => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <FirebaseContent/>
            </QueryClientProvider>
        </>
    )
}

const FirebaseContent = () => {
    const queryFn = async () => {
        const ref = collection(fs, 'item');
        const q = query(ref);
        const querySnapshot = await getDocs(q);
        let output = [];
        querySnapshot.forEach((doc, index) => {
            let data = doc.data();
            data.key = index;
            output.push(data)
        });
        return output
    }

    const {
        data,
        status,
        refetch,
    } = useQuery({
        queryKey: ['item-list'],
        queryFn: queryFn,
        retry: 1
    })

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        検索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        リセット
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        中止
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        // setSortedInfo(sorter);
    };
    const getColumnFilterProps = (dataIndex, filters) => ({
        filters: filters,
        filteredValue: filteredInfo[dataIndex] || null,
        onFilter: (value, record) => record[dataIndex].includes(value),
    })

    return (
        <>
            <Table
                dataSource={data?.map(v => {
                    return {
                        ...v
                    }
                })}
                rowKey={(record) => record.item_name}
                sticky
                onChange={handleChange}
                expandable={{
                    expandedRowRender: (record) => (
                        <>no contents</>
                    ),
                }}
                expandRowByClick
                pagination={{
                    pageSize: 50,
                }}
            >
                <Table.Column
                    title='商品名'
                    dataIndex="item_name"
                    key="item_name"
                    width='150px'
                    {...getColumnSearchProps("item_name")}
                />
                <Table.Column
                    title='ステータス'
                    dataIndex="publish"
                    key="publish"
                    width='100px'
                    {...getColumnSearchProps("publish")}
                    render={(status) => status ? <Tag color="green">公開中</Tag> : <Tag color="red">未公開</Tag>}
                />
                <Table.ColumnGroup title='カテゴリ'>
                    <Table.Column
                        title='T1'
                        dataIndex="t1"
                        key="t1"
                        width='150px'
                        {...getColumnSearchProps("t1")}
                    />
                    <Table.Column
                        title='T2'
                        dataIndex="t2"
                        key="t2"
                        width='150px'
                        {...getColumnSearchProps("t2")}
                    />
                    <Table.Column
                        title='T3'
                        dataIndex="t3"
                        key="t3"
                        width='150px'
                        {...getColumnSearchProps("t3")}
                    />
                    <Table.Column
                        title='T4'
                        dataIndex="t4"
                        key="t4"
                        width='150px'
                        {...getColumnSearchProps("t4")}
                    />
                </Table.ColumnGroup>
            </Table>
        </>
    )
}

const ExpandedArea = (props) => {
    let record = props.record;
    const [draft, setDraft] = useState(record.station_agent_from);
    const [edited, setEdited] = useState(false);
    const [loading, setLoading] = useState(false);
    const updateHandler = async () => {
        setLoading(true);
        const ref = doc(fs, "plan", record.plan_id);
        await updateDoc(ref, {
            station_agent_from: draft
        });
        props.onUpdate();
        setLoading(false);
        setEdited(false);
    }
    return (
        <Space direction='vertical' style={{width: '100%'}}>
            <Row>
                <Button
                    type='primary'
                    disabled={!edited}
                    loading={loading}
                    onClick={() => {
                        updateHandler();
                    }}
                >
                    更新
                </Button>
            </Row>
        </Space>
    )
}
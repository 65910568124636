import React from 'react';
import {Select} from 'antd';

export const SelectMethod = (props) => {
    const handleChange = (value) => {
        props.onChange(value);
    }
    return (
        <Select
            mode="multiple"
            allowClear
            style={{
                width: '100%',
            }}
            defaultValue={props.defaultValue}
            placeholder="方法を選択"
            onChange={handleChange}
            options={props.options}
        />
    )
}
import React, {useState, useEffect} from "react";
import {Button, Col, Descriptions, Form, Input, Row, Select, Space, Switch, Typography} from "antd";
import {collection, doc, getDocs, getFirestore, query, setDoc, updateDoc} from "firebase/firestore";
import {initializeApp} from "firebase/app";
import {SelectMethod} from "./Components";
import {objFormatterForFirestore} from '../../function/tools';
import {useQuery} from "@tanstack/react-query";
import {DownCircleOutlined, MinusCircleOutlined, UpCircleOutlined} from "@ant-design/icons";

const firebaseConfig = {
    apiKey: process.env.FIREBASE_YUZURUN_API_KEY,
    authDomain: "rx-0002.firebaseapp.com",
    projectId: "rx-0002",
    appId: process.env.FIREBASE_YUZURUN_APP_ID
};
const planList = initializeApp(firebaseConfig);
const fs = getFirestore(planList);

export default (props) => {
    let record = props.record;
    const [draft, setDraft] = useState(record);
    const [edited, setEdited] = useState(false);
    const [loading, setLoading] = useState(false);
    const updateHandler = async () => {
        setLoading(true);
        const formattedValue = objFormatterForFirestore(draft);
        const ref = doc(fs, "plan", record.plan_id);
        await updateDoc(ref, formattedValue);
        props.onUpdate();
        setLoading(false);
        setEdited(false);
    }
    useEffect(() => {
        console.log(draft)
    }, [draft])
    return (
        <>
            <Descriptions
                bordered
                layout='horizontal'
                column={1}
                labelStyle={{width: '200px'}}
            >
                <Descriptions.Item label="発送箇所別の配送方法">
                    <Space direction='vertical' style={{width: '100%'}}>
                        {record.station_agent_from?.map((agent) => {
                            return (
                                <Row justify='middle' gutter={16}>
                                    <Col span={4}>
                                        <Typography.Text strong>{agent.station_agent_name}</Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <SelectMethod
                                            defaultValue={agent.method_key_list}
                                            onChange={(v) => {
                                                setDraft({
                                                    ...draft,
                                                    station_agent_from: draft.station_agent_from?.map((row) => {
                                                        return row.station_agent_name === agent.station_agent_name
                                                            ? {...row, method_key_list: v}
                                                            : row
                                                    })
                                                })
                                                setEdited(true);
                                            }}
                                            options={props.options}
                                        />
                                    </Col>
                                </Row>
                            )
                        })}
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="お知らせ・注意">
                    <Form
                        onValuesChange={(currentVal, allVal) => {
                            setDraft({
                                ...draft,
                                ...allVal
                            })
                            setEdited(true);
                        }}
                    >
                        <FormListDescription name='notice' formValue={draft}/>
                    </Form>
                </Descriptions.Item>
            </Descriptions>
            <Button
                type='primary'
                disabled={!edited}
                loading={loading}
                onClick={() => {
                    updateHandler();
                }}
            >
                更新
            </Button>
        </>
    )
}


const FormListDescription = (props) => {
    const queryOptions = async () => {
        const methodsRef = collection(fs, 'method_content');
        const q = query(methodsRef);
        const querySnapshot = await getDocs(q);
        let output = [];
        querySnapshot.forEach((doc, index) => {
            let data = doc.data();
            data.key = index;
            output.push({value: data.method_content_key, label: data.title_internal})
        });
        return output
    }

    const {
        data: optionsData,
        status: optionsStatus,
        refetch: optionsRefetch
    } = useQuery({
        queryKey: ['method-content-list'],
        queryFn: queryOptions,
        retry: 1
    })

    return (
        <Form.List name={props.name}>
            {(fields, {add, move, remove}, {errors}) => (
                <>
                    <Space direction='vertical'>
                        {fields.map(({key, name, ...restField}, index) => (
                            <Space align="baseline"
                                   style={{
                                       display: 'flex',
                                       marginBottom: 8
                                   }}>
                                <Row className='description-area'>
                                    <Col span={1}>
                                        <span>{index + 1}.</span>
                                    </Col>
                                    <Col span={23}>
                                        <Row>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'description_item']}
                                                wrapperCol={{span: 24}}
                                                style={{width: '600px'}}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="アイテムを選択"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={optionsData}
                                                    allowClear
                                                />
                                            </Form.Item>
                                        </Row>
                                        <Row>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'description']}
                                                wrapperCol={{span: 24}}
                                                style={{width: '600px'}}
                                            >
                                                <Input.TextArea
                                                    placeholder="説明テキスト"
                                                    rows={4}
                                                    disabled={props.formValue && !!props.formValue[props.name]?.[name]?.description_item}
                                                />
                                            </Form.Item>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <FormListButtons
                                                    name={[name, 'btnList']}
                                                    formValue={props.formValue}
                                                    disabled={props.formValue && !!props.formValue[props.name]?.[name]?.description_item}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <MinusCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => remove(name)}
                                />
                                <UpCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => move(index, index - 1)}
                                />
                                <DownCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => move(index, index + 1)}
                                />
                            </Space>
                        ))}
                    </Space>
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                        >
                            項目追加
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    )
}

const FormListButtons = (props) => {
    return (
        <Form.List name={props.name}>
            {(fields, {add, move, remove}, {errors}) => (
                <>
                    <Space direction='vertical'>
                        {fields.map(({key, name, ...restField}, index) => (
                            <Space
                                key={key}
                                style={{
                                    display: 'flex',
                                    marginBottom: 8
                                }}
                                align="baseline"
                            >
                                <Form.Item
                                    {...restField}
                                    name={[name, 'btnText']}
                                    wrapperCol={{span: 24}}
                                    style={{width: '200px'}}
                                    defaultValue={null}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="ボタンテキスト"
                                        disabled={props.disabled}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'btnHref']}
                                    wrapperCol={{span: 24}}
                                    style={{width: '200px'}}
                                    defaultValue={null}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="https://..."
                                        disabled={props.disabled}
                                    />
                                </Form.Item>
                                <MinusCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => remove(name)}
                                />
                                <UpCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => move(index, index - 1)}
                                />
                                <DownCircleOutlined
                                    style={{fontSize: '24px'}}
                                    onClick={() => move(index, index + 1)}
                                />
                            </Space>
                        ))}
                    </Space>
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => {
                                add();
                            }}
                            disabled={props.disabled}
                        >
                            ボタン追加
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    )
}

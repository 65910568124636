import React, {useState, useEffect, useRef} from 'react';
import {Typography, Table, Button, Input, Space, Tag, Row, Col, message, Progress, Spin} from 'antd';
import {initializeApp} from "firebase/app";
import {getFirestore, collection, query, doc, getDocs, updateDoc} from "firebase/firestore";
import {useQuery, useQueryClient, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import EditableTable from '../../../component/block/EditableTable';
import {objFormatterForFirestore} from "../../../function/tools";

const firebaseConfig = {
    apiKey: process.env.FIREBASE_YUZURUN_API_KEY,
    authDomain: "rx-0002.firebaseapp.com",
    projectId: "rx-0002",
    appId: process.env.FIREBASE_YUZURUN_APP_ID
};
const planList = initializeApp(firebaseConfig);
const fs = getFirestore(planList);

const queryClient = new QueryClient();

export default (props) => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <FirebaseContent/>
            </QueryClientProvider>
        </>
    )
}

const FirebaseContent = () => {
    const [loading, setLoading] = useState(false);
    const queryFn = async () => {
        const plansRef = collection(fs, 'lp-service');
        const q = query(plansRef);
        const querySnapshot = await getDocs(q);
        let output = [];
        querySnapshot.forEach((doc, index) => {
            let data = doc.data();
            data.key = index;
            output.push(data)
        });
        return output
    }

    const {
        data,
        status,
        refetch,
    } = useQuery({
        queryKey: ['lp-service'],
        queryFn: queryFn,
        retry: 1
    })

    const updateHandler = async (row) => {
        setLoading(true);
        const formattedValue = objFormatterForFirestore(row);
        const ref = doc(fs, "lp-service", row.service_key);
        await updateDoc(ref, formattedValue);
        setLoading(false);
    }
    const createHandler = async (row) => {
        setLoading(true);
        const formattedValue = objFormatterForFirestore(row);
        const ref = fs.collection("lp-service").doc();
        await ref.set(formattedValue);
        setLoading(false);
    }

    return (
        <>
            <Spin spinning={loading}>
                <EditableTable
                    dataSource={data}
                    defaultColumns={[
                        {
                            title: 'Key',
                            dataIndex: 'service_key',
                            key: "service_key",
                            width: '200px',
                            render: (v) => <a href={`https://dev.ocurun.com/lp/service/${v}`} target='_blank'>{v}</a>
                        },
                        {
                            title: '公開',
                            dataIndex: 'publish',
                            key: "publish",
                            width: '150px',
                            inputType: 'select-single',
                            options: [
                                {
                                    label: '公開',
                                    value: true,
                                },
                                {
                                    label: '非公開',
                                    value: false,
                                },
                            ],
                            editable: true,
                            render: (v) => v ? <Tag color="green">公開中</Tag> : <Tag color="red">非公開</Tag>
                        },
                        {
                            title: 'キャリア名',
                            dataIndex: 'carrier_name',
                            key: "carrier_name",
                            width: '150px',
                            editable: true,
                        },
                        {
                            title: '配送サービス名',
                            dataIndex: 'ship_service_name',
                            key: "ship_service_name",
                            width: '200px',
                            editable: true,
                        },
                        {
                            title: '割引名',
                            dataIndex: 'discount_plan_name',
                            key: "discount_plan_name",
                            width: '200px',
                            editable: true,
                        },
                        {
                            title: 'robots',
                            dataIndex: "robots",
                            key: "robots",
                            inputType: 'select-multiple',
                            options: [
                                {
                                    label: 'noindex',
                                    value: 'noindex',
                                },
                                {
                                    label: 'nofollow',
                                    value: 'nofollow',
                                },
                                {
                                    label: 'noarchive',
                                    value: 'noarchive',
                                },
                                {
                                    label: 'noimageindex',
                                    value: 'noimageindex',
                                },
                            ],
                            editable: true,
                        },
                    ]}
                    onCreate={(row) => createHandler(row)}
                    onUpdate={(row) => updateHandler(row)}
                />
            </Spin>
        </>
    )
}
import React, {useState, useEffect, useRef} from 'react';
import {Typography, Table, Button, Input, Space, Tag, Row, Col, message, Progress, Spin, Select} from 'antd';
import {initializeApp} from "firebase/app";
import {getFirestore, collection, query, doc, getDocs, setDoc, addDoc, updateDoc, deleteDoc} from "firebase/firestore";
import {useQuery, useQueryClient, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import EditableTable from '../../../component/block/EditableTable';
import {objFormatterForFirestore} from "../../../function/tools";

const firebaseConfig = {
    apiKey: process.env.FIREBASE_YUZURUN_API_KEY,
    authDomain: "rx-0002.firebaseapp.com",
    projectId: "rx-0002",
    appId: process.env.FIREBASE_YUZURUN_APP_ID
};
const fsList = initializeApp(firebaseConfig);
const fs = getFirestore(fsList);

const queryClient = new QueryClient();

export default (props) => {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <FirebaseContent/>
            </QueryClientProvider>
        </>
    )
}

const FirebaseContent = () => {
    const [loading, setLoading] = useState(false);
    const queryFn = async () => {
        const plansRef = collection(fs, 'page-link');
        const q = query(plansRef);
        const querySnapshot = await getDocs(q);
        let output = [];
        querySnapshot.forEach((doc, index) => {
            let data = doc.data();
            data.key = index;
            data.id = doc.id;
            output.push(data)
        });
        return output
    }

    const {
        data,
        status,
        refetch,
    } = useQuery({
        queryKey: ['page-link'],
        queryFn: queryFn,
        retry: 1
    })

    const queryOptions = async () => {
        const plansRef = collection(fs, 'internal-link');
        const q = query(plansRef);
        const querySnapshot = await getDocs(q);
        let output = [];
        querySnapshot.forEach((doc, index) => {
            let data = doc.data();
            data.key = index;
            data.id = doc.id;
            output.push(data);
        });
        return output
    }

    const {
        data: optionsData,
        status: optionsStatus,
        refetch: optionsRefetch,
    } = useQuery({
        queryKey: ['internal-link'],
        queryFn: queryOptions,
        retry: 1
    })

    const updateHandler = async (row) => {
        setLoading(true);
        console.log(row);
        const formattedValue = objFormatterForFirestore(row);
        const ref = doc(fs, 'page-link', row.id);
        await updateDoc(ref, formattedValue);
        setLoading(false);
    }
    const createHandler = async (row) => {
        setLoading(true);
        const formattedValue = objFormatterForFirestore(row);
        await setDoc(collection(fs, 'page-link', row.page_id), formattedValue);
        // const docRef = await addDoc(collection(fs, 'page-link'), formattedValue);
        // console.log('Row successfully added. ID:' + docRef.id);
        refetch();
        setLoading(false);
    }
    const deleteHandler = async (id) => {
        setLoading(true);
        await deleteDoc(doc(fs, 'page-link', id.toString()))
        console.log('Row successfully deleted. ID:' + id);
        refetch();
        setLoading(false);
    }

    return (
        <>
            <Spin spinning={loading}>
                <EditableTable
                    dataSource={data}
                    defaultColumns={[
                        {
                            title: 'Page ID',
                            dataIndex: 'id',
                            width: '150px',
                        },
                        {
                            title: 'ページ名（参照）',
                            dataIndex: ['reference', 'name'],
                            width: '200px',
                        },
                        {
                            title: 'カテゴリ（参照）',
                            dataIndex: ['reference', 'category'],
                            width: '100px',
                        },
                        {
                            title: 'ディレクトリ（参照）',
                            dataIndex: ['reference', 'directory'],
                            width: '150px',
                            render: (v) => <a href={`https://dev.ocurun.com${v}`} target='_blank'>{v}</a>
                        },
                        {
                            title: '表示リンク（一覧）',
                            dataIndex: 'linkList',
                            inputType: 'select-multiple',
                            options: optionsData?.map(r => {
                                return {
                                    label: `${r.display_name || '表示名未指定'}(${r.href || 'リンク先未指定'})`,
                                    value: r.id,
                                }
                            }),
                            render: (v) => v.map(id => optionsData?.filter(r => r.id === id)[0].display_name || '表示名未指定'),
                            editable: true,
                        },
                        {
                            title: '公開',
                            dataIndex: 'publish',
                            width: '150px',
                            inputType: 'select-single',
                            options: [
                                {
                                    label: '公開',
                                    value: true,
                                },
                                {
                                    label: '非公開',
                                    value: false,
                                },
                            ],
                            editable: true,
                            render: (v) => v ? <Tag color="green">公開中</Tag> : <Tag color="red">非公開</Tag>
                        },
                    ]}
                    onCreate={(row) => createHandler(row)}
                    onUpdate={(row) => updateHandler(row)}
                    onDelete={(id) => deleteHandler(id)}
                    addEnabled={false}
                />
            </Spin>
        </>
    )
}